import { createNextState } from '@reduxjs/toolkit';
import { invariant } from 'folio-common-utils';
import { getType } from 'typesafe-actions';
import { type FoundingAction, type FoundingReducerState, actions } from './';

function makeInitialState(): FoundingReducerState {
  return {
    id: '',
    signerURLs: [],
    signingStatus: 'OPEN',
    documentURL: null,
    uploadReceiptURL: null,
    events: [],
    accountNumber: null,
    foundingDetails: null,
    bankSelection: null,
    virksomhetsInfoSignerURLs: [],
    orgTransferSignerURLs: [],
    canUpdatePersonDetails: false,
  };
}

export const reducer = createNextState(
  (draft: FoundingReducerState, action: FoundingAction) => {
    switch (action.type) {
      case getType(actions.updateFounding): {
        return Object.assign(draft, action.payload);
      }

      case getType(actions.reset): {
        return Object.assign(draft, makeInitialState());
      }

      case getType(actions.updateContactInfo): {
        const { id, email, phone } = action.payload;

        invariant(draft.foundingDetails, 'foundingDetails must exist');
        invariant(email && phone, 'contact info must exist');
        draft.foundingDetails.people.forEach(person => {
          if (person.id === id) {
            person.email = email;
            person.mobile = phone;
          }
        });
        return draft;
      }

      default:
        return draft;
    }
  },

  makeInitialState(),
);
